import React, { Component } from 'react';
import PublicPage from '../common/containers/PublicPage';
import { withNamespaces } from 'react-i18next';
import Content from '../common/containers/Content';
import Col from '../common/containers/Col';
import Row from '../common/containers/Row';
import './about.css';

class AboutPage extends Component {
  render() {
    const { i18n } = this.props;
    return (
      <PublicPage title={i18n.t('Sobre')}>
        <Content>
          <Row>
            <Col md={6} mdOffset={3}>
              <br />
              <h2>FastFormat</h2>
              <br />
              <p>
                O <strong>FastFormat</strong> é um software online inovador que facilita a criação e formatação de
                documentos e apresentações de maneira automatizada, ideal para estudantes, profissionais e acadêmicos.
                Com funcionalidades avançadas e uma interface intuitiva, o FastFormat garante que seus trabalhos sejam
                apresentados de forma clara, organizada e profissional, sem a necessidade de ajustes manuais.
              </p>

              <p>
                Com mais de 400 modelos e mais de 15 anos de experiência de seus criadores, o FastFormat é referência de
                qualidade no mercado acadêmico.
              </p>

              <h3>Formatação de Documentos</h3>
              <p>
                O FastFormat automatiza completamente a formatação de documentos, cuidando de todos os aspectos
                técnicos, como margens, espaçamento, cabeçalhos, rodapés e estilos de fonte. Ele ajusta os documentos de
                acordo com padrões acadêmicos ou personalizados, garantindo que o conteúdo siga as normas específicas,
                como <strong>ABNT, APA, MLA e várias outras</strong>. O software conta com modelos específicos para
                universidades, revistas e conferências científicas.
              </p>
              <h4>Formatação Automática de Referências Bibliográficas</h4>
              <p>
                Uma das principais vantagens do FastFormat é sua{' '}
                <strong>formatação automática de referências bibliográficas</strong>. O software gera e ajusta as
                citações e a lista de referências ao final do documento, seguindo as normas corretas. Tudo que o usuário
                precisa fazer é inserir as informações das fontes, e o FastFormat organiza automaticamente, poupando
                tempo e evitando erros de formatação.
              </p>
              <h3>Formatação de Apresentações</h3>
              <p>
                O FastFormat também automatiza a <strong>formatação de apresentações</strong>, proporcionando um design
                consistente e profissional. Ele ajusta layout, fontes e cores, criando slides visualmente agradáveis e
                bem organizados. Além disso, o software tem a funcionalidade exclusiva de{' '}
                <strong>criar uma apresentação diretamente a partir de um documento</strong>, extraindo os principais
                pontos e organizando-os em uma estrutura de slides coerente, facilitando a criação de apresentações de
                forma rápida e eficiente.
              </p>
              <h3>Funcionalidades Adicionais</h3>
              <ul>
                <li>
                  <p>
                    <strong>Gerenciamento de Bibliografia</strong>: O software permite que você organize e gerencie suas
                    referências de forma prática, gerando citações e bibliografias de maneira automatizada.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Marcação de PDF</strong>: Com o FastFormat, você pode adicionar anotações, destaques e
                    comentários diretamente em arquivos PDF, tornando a revisão e a análise de documentos mais
                    eficiente.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Revisão Ortográfica Automática</strong>: O software oferece uma revisão ortográfica
                    integrada, identificando e corrigindo erros gramaticais e ortográficos automaticamente, garantindo a
                    clareza e correção do seu texto.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Revisões Manuais para Alunos e Professores</strong>: Para ambientes acadêmicos, o FastFormat
                    oferece uma ferramenta de revisão colaborativa, onde professores podem revisar e comentar
                    documentos, ajudando os alunos com feedback direto.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Histórico de Alterações</strong>: O software mantém um histórico de todas as alterações
                    realizadas no documento, permitindo que você visualize e reverta mudanças quando necessário.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Edição de Imagens</strong>: FastFormat também permite a <strong>edição de imagens</strong>{' '}
                    diretamente na plataforma, garantindo que seus documentos e apresentações incluam elementos visuais
                    profissionais e bem ajustados.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Importação do Microsoft Word</strong>: O software facilita a importação de documentos
                    criados no Microsoft Word, preservando o conteúdo e aplicando a formatação automaticamente, sem a
                    necessidade de começar do zero.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Modelos de Conteúdo</strong>: O FastFormat oferece diversos{' '}
                    <strong>modelos de conteúdo</strong>, que permitem que você comece seus projetos com uma base
                    sólida, seja para documentos ou apresentações, facilitando ainda mais o processo criativo.
                  </p>
                </li>
              </ul>
              <p>
                O <strong>FastFormat</strong> é a ferramenta definitiva para quem precisa de praticidade e precisão na
                formatação de documentos e apresentações, com funcionalidades avançadas que otimizam o tempo e garantem
                um resultado final de alta qualidade. Seja no ambiente acadêmico ou profissional, o FastFormat é a
                solução completa para criar, organizar e formatar seus trabalhos com eficiência e facilidade.
              </p>

              <h2>A equipe</h2>
              <p>
                A equipe que desenvolve o FastFormat e fornece o suporte aos usuários é formada por pessoas experientes
                na área acadêmica. Nós já passamos por todas as dificuldades inerentes a produção de texto acadêmicos e
                científicos. Essa experiência, juntamente com nossa formação, permite-nos fornecer serviços exclusivos e
                de qualidade aos nossos usuários.
              </p>

              <h2>Missão</h2>
              <p>
                <i>
                  Nossa missão é eliminar qualquer complexidade tecnológica referente a escrita acadêmica e científica,
                  fazendo com que nossos usuários desenvolvam todo seu potencial na produção de novos conhecimentos.
                </i>
              </p>

              <hr />
              <h2>&copy; 2015-{new Date().getFullYear()} FastFormat</h2>
              <p>
                BPY Soluções Tecnológicas Ltda - ME
                <br />
                CNPJ 25.126.480/0001­62
                <br />
                Rua do Sossego, n 298, Caixa Postal 383
                <br />
                Boa Vista, Recife-PE
                <br />
                CEP 50050-­080
              </p>
            </Col>
          </Row>
        </Content>
      </PublicPage>
    );
  }
}

export default withNamespaces()(AboutPage);
